import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { usePageLayoutContext } from "@/context/page-layout/page-layout-context";
import { useSessionDetailsContext } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { Title } from "../../session-detail.styles";
import { isComponentPartOfSharedRow } from "../../utils/utils";

import {
    ColoredOverlay,
    Overlay,
    SectionPercentage,
    DataContainer,
    LeftSection,
    CenterTopSection,
    CenterBottomSection,
    RightSection,
    EyeTrackingImage,
    CenterSection,
    SectionText,
    Container,
} from "./styles";

const getGazeEyeValue = (gazeEyeData: any, key: string) => {
    return gazeEyeData?.datasets[0]?.[key];
};

//Gets the background image url from blob storage.
const getGazeEyeBackgroundImage = (gazeEyeData: any, key: string) => {
    const backgroundImageUrl = gazeEyeData?.datasets[0]?.[key];

    return backgroundImageUrl ?? "/assets/default-eye-tracking-bg.png";
};

export const EyeTracking = ({ ...remainingProps }) => {
    const { combinedChartsData, isFetchingCombinedCharts } = useSessionDetailsContext();
    const { gridLayoutData } = usePageLayoutContext();

    const isPartOfSharedRow = useMemo(() => {
        return isComponentPartOfSharedRow(gridLayoutData, "EyeTracking");
    }, [gridLayoutData]);

    // Filter the data to get the object with the code 'GAZE_EYE'
    const gazeEyeData = combinedChartsData?.find((item: any) => item.code === "GAZE_EYE");

    const hasNoData = gazeEyeData?.datasets.length === 0;

    const gazeEyeDataSets = gazeEyeData?.datasets[0];

    const valuesArray = useMemo(() => {
        if (hasNoData) {
            return [];
        }

        return Object.entries(gazeEyeDataSets || {})
            .filter(([key]) =>
                ["TOP_MIRROR", "BOTTOM_MIRROR", "LEFT_MIRROR", "RIGHT_MIRROR"].includes(key),
            )
            .map(([, value]) => value);
    }, [hasNoData, gazeEyeDataSets]);

    const isSingleValueZero = (value: any) => value === 0;

    const areAllValuesZero = valuesArray.every(isSingleValueZero);

    const showEmptyState = hasNoData || areAllValuesZero;

    //this div is needed for the loader container to render inside the Modal, with the provided height
    if (isFetchingCombinedCharts) {
        return (
            <div
                style={{
                    height: isPartOfSharedRow ? "21.1875rem" : "26.4375rem",
                    width: "100%",
                }}
            >
                <ContentLoader height={isPartOfSharedRow ? "21.1875rem" : "26.4375rem"} />
            </div>
        );
    }

    return (
        <Container isPartOfSharedRow={isPartOfSharedRow} {...remainingProps}>
            <Title>
                <FormattedMessage id={"session-detail:gaze-eye-tracking"} />
                <Tooltip simpleText={gazeEyeData?.description} />
            </Title>

            {showEmptyState ? (
                <EmptyState
                    emptyStateType={EmptyStateType.Charts}
                    title="session-details:eye-tracking-empty-state-description"
                />
            ) : (
                <DataContainer isPartOfSharedRow={isPartOfSharedRow}>
                    <EyeTrackingImage
                        src={getGazeEyeBackgroundImage(gazeEyeData, "BackgroundImage")}
                    />
                    <ColoredOverlay />
                    <Overlay>
                        <LeftSection>
                            <div>
                                <SectionText>
                                    <FormattedMessage id={"session-detail:nearside"} />
                                </SectionText>
                                <SectionPercentage>
                                    {getGazeEyeValue(gazeEyeData, "LEFT_MIRROR")}%
                                </SectionPercentage>
                            </div>
                        </LeftSection>
                        <CenterSection>
                            <CenterTopSection>
                                <div>
                                    <SectionText>
                                        <FormattedMessage id={"session-detail:far-distance"} />
                                    </SectionText>
                                    <SectionPercentage>
                                        {getGazeEyeValue(gazeEyeData, "TOP_MIRROR")}%
                                    </SectionPercentage>
                                </div>
                            </CenterTopSection>
                            <CenterBottomSection>
                                <div>
                                    <SectionText>
                                        <FormattedMessage id={"session-detail:near-distance"} />
                                    </SectionText>
                                    <SectionPercentage>
                                        {getGazeEyeValue(gazeEyeData, "BOTTOM_MIRROR")}%
                                    </SectionPercentage>
                                </div>
                            </CenterBottomSection>
                        </CenterSection>
                        <RightSection>
                            <div>
                                <SectionText>
                                    <FormattedMessage id={"session-detail:offside"} />
                                </SectionText>
                                <SectionPercentage>
                                    {getGazeEyeValue(gazeEyeData, "RIGHT_MIRROR")}%
                                </SectionPercentage>
                            </div>
                        </RightSection>
                    </Overlay>
                </DataContainer>
            )}
        </Container>
    );
};
