import { useCallback, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";

import { Portal } from "@mui/material";

import { ChevronDownSquareCornersIcon20, ChevronDownSquareCornersIcon24 } from "@/components/icons";

import { DropdownFilterVariant } from "@/types/filters";

import {
    ArrowIconWrapper,
    Container,
    List,
    ListContainer,
    PopUp,
    PopUpListItem,
    Toggle,
} from "../filters.styles";

import { Checkbox } from "./components/checkbox/checkbox";
import { CheckboxContainer } from "./components/checkbox/checkbox.styles";

import type { MultiSelectDropdownProps, MultiSelectOption } from "./multi-select.types";

import {
    SelectAllContainer,
    SelectedCountSpan,
    SeparatorLine,
    TextSpan,
    TitleAndCountWrapper,
} from "./multi-select.styles";

export const MultiSelectDropdown = ({
    handleOnClick,
    onSelectionChange,
    options,
    title,
    icon,
    showSelectAll = false,
    variant = DropdownFilterVariant.Primary,
    initialSelectedOptions = [],
    popUpWidth,
    ...props
}: MultiSelectDropdownProps) => {
    const [selectedOptions, setSelectedOptions] =
        useState<MultiSelectOption[]>(initialSelectedOptions);
    const [isOpen, setIsOpen] = useState(false);
    const [popUpStyle, setPopUpStyle] = useState<React.CSSProperties>({});

    //populate initial selected options
    useEffect(() => {
        setSelectedOptions(initialSelectedOptions);
    }, [initialSelectedOptions]);

    const handleToggleList = useCallback(() => {
        setIsOpen((currentIsOpen) => !currentIsOpen);
    }, []);

    const handleOptionToggle = useCallback(
        (option: MultiSelectOption) => {
            setSelectedOptions((prevSelected) => {
                const isSelected = prevSelected.some(({ id }) => id === option.id);
                const newSelected = isSelected
                    ? prevSelected.filter(({ id }) => id !== option.id)
                    : [...prevSelected, option];

                onSelectionChange(newSelected);

                return newSelected;
            });
        },
        [onSelectionChange],
    );

    const handleSelectAll = useCallback(() => {
        const allSelected = options.length === selectedOptions.length;
        const newSelected = allSelected ? [] : options;

        setSelectedOptions(newSelected);
        onSelectionChange(newSelected);
    }, [options, selectedOptions, onSelectionChange]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: () => {
            setIsOpen(false);
            handleOnClick(null);
        },
    });

    //define the PopUp position, in relation to the Toggle
    useEffect(() => {
        if (isOpen) {
            const toggleButton = document.getElementById("multi-select-toggle-button");

            if (toggleButton) {
                const rect = toggleButton.getBoundingClientRect();
                setPopUpStyle({
                    top: `${rect.bottom + 8}px`,
                    right: `${document.documentElement.clientWidth - rect.right}px`,
                });
            }
        }
    }, [isOpen]);

    return (
        <Container ref={clickOutsideRef} {...props}>
            <Toggle
                id="multi-select-toggle-button"
                isOpen={isOpen}
                variant={variant}
                onClick={handleToggleList}
            >
                {icon}
                <TitleAndCountWrapper>
                    <span>{title}</span>
                    <SelectedCountSpan>{selectedOptions.length}</SelectedCountSpan>
                </TitleAndCountWrapper>
                <ArrowIconWrapper isOpen={isOpen}>
                    {variant === DropdownFilterVariant.Primary ? (
                        <ChevronDownSquareCornersIcon24 />
                    ) : (
                        <ChevronDownSquareCornersIcon20 />
                    )}
                </ArrowIconWrapper>
            </Toggle>

            {isOpen && (
                <Portal>
                    <PopUp
                        style={popUpStyle}
                        popUpWidth={popUpWidth}
                        variant={DropdownFilterVariant.Primary}
                        onClick={(e) => e.stopPropagation()} //prevents closing when clicking an option
                    >
                        <ListContainer>
                            {showSelectAll && (
                                <SelectAllContainer
                                    isChecked={options.length === selectedOptions.length}
                                    onClick={handleSelectAll}
                                    variant={variant}
                                >
                                    <CheckboxContainer>
                                        <Checkbox
                                            checked={options.length === selectedOptions.length}
                                            onChange={handleSelectAll}
                                        />
                                    </CheckboxContainer>
                                    <TextSpan
                                        className={
                                            options.length === selectedOptions.length
                                                ? "checked"
                                                : ""
                                        }
                                    >
                                        <FormattedMessage id="filters:multi-select:select-all" />
                                    </TextSpan>
                                    <SeparatorLine />
                                </SelectAllContainer>
                            )}

                            <List>
                                {options.map((option) => {
                                    return (
                                        <PopUpListItem
                                            key={option.id}
                                            variant={variant}
                                            isSelected={selectedOptions.some(
                                                ({ id }) => id === option.id,
                                            )}
                                            onClick={() => handleOptionToggle(option)}
                                        >
                                            <CheckboxContainer>
                                                <Checkbox
                                                    checked={selectedOptions.some(
                                                        ({ id }) => id === option.id,
                                                    )}
                                                    onChange={() => handleOptionToggle(option)}
                                                />
                                            </CheckboxContainer>
                                            <TextSpan
                                                className={
                                                    selectedOptions.some(
                                                        ({ id }) => id === option.id,
                                                    )
                                                        ? "checked"
                                                        : ""
                                                }
                                            >
                                                {option.label}
                                            </TextSpan>
                                        </PopUpListItem>
                                    );
                                })}
                            </List>
                        </ListContainer>
                    </PopUp>
                </Portal>
            )}
        </Container>
    );
};
