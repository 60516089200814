import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { useQuery } from "react-query";

import { getLayoutGrid } from "@/api/settings";

import useQueryParams from "@/utils/use-query-params";

interface PageLayoutType {
    isFetchingGridLayoutData: boolean;
    gridLayoutData: any;
    isFetchedGridLayoutData: boolean;
}

//create the context
const PageLayoutContext = createContext<PageLayoutType | undefined>(undefined);

//create the provider
export const PageLayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const params: any = useQueryParams();
    const simulationId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    //PageLayout
    //API request: /page-layout
    const {
        isFetching: isFetchingGridLayoutData,
        data: gridLayoutData,
        isFetched: isFetchedGridLayoutData,
    } = useQuery(
        [simulationId, scenarioInstanceId],
        () => getLayoutGrid({ simulationId, scenarioInstanceId }),
        {
            suspense: true, //has to be true in order for the components to render,
            refetchOnMount: false, //caches data, enables manual data refetch
            useErrorBoundary: false,
            enabled: true,
        },
    );

    return (
        <PageLayoutContext.Provider
            value={{ isFetchingGridLayoutData, gridLayoutData, isFetchedGridLayoutData }}
        >
            {children}
        </PageLayoutContext.Provider>
    );
};

export const usePageLayoutContext = (): PageLayoutType => {
    const context = useContext(PageLayoutContext);

    if (!context) {
        throw new Error("usePageLayoutContext hook must be used within SessionDetailsPageProvider");
    }

    return context;
};
