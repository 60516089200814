import React, { useCallback } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
    ChevronDownSquareCornersIcon24,
    SortDashesIcon24,
    SortDashesIcon32,
} from "@/components/icons";

import { DropdownFilterVariant, SortingFilterEnum } from "@/types/filters";

import { FiltersList } from "@/enums/dashboard";

import useMediaQuery from "@/utils/hooks/use-media-query";

import {
    ArrowIconWrapper,
    Container,
    PopUp,
    PopUpListItem,
    StyledText,
    Toggle,
} from "./filters.styles";

interface SortingFiltersProps {
    isOpen: boolean;
    handleOnClick: (filterName: FiltersList) => void;
    onSortingChange: (sortingOption: string | null) => void;
    sortingOption: string | null;
    backgroundColor?: string;
}

const SortingFilters = ({
    isOpen,
    handleOnClick,
    onSortingChange,
    sortingOption,
}: SortingFiltersProps) => {
    const history = useHistory();
    const isMobileAndTablet = useMediaQuery("(max-width: 1023px)");

    const sortingOptionText =
        sortingOption === SortingFilterEnum.LeastRecent
            ? "sorting-filters:least-recent"
            : "sorting-filters:most-recent";

    const onClose = useCallback(() => {
        if (!isOpen) return;
        handleOnClick(FiltersList.None);
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: onClose,
    });

    const handleToggleList = useCallback(
        (event: any) => {
            event.stopPropagation();
            handleOnClick(isOpen ? FiltersList.None : FiltersList.OrderBy);
        },
        [isOpen, handleOnClick],
    );

    const handleOptionClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            const sortingOptionClicked = event.currentTarget.id; //ascending or descending
            event.stopPropagation();
            onSortingChange(sortingOptionClicked);
            handleOnClick(FiltersList.None);

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("orderBy", sortingOptionClicked);
            history.push({ search: queryParams.toString() });
        },
        [onSortingChange],
    );

    return (
        <>
            <Container ref={clickOutsideRef}>
                {isMobileAndTablet && (
                    <Toggle
                        isOpen={isOpen}
                        variant={DropdownFilterVariant.Primary}
                        onClick={handleToggleList}
                        data-test-id="recent-dd"
                    >
                        <SortDashesIcon32 />
                    </Toggle>
                )}
                {!isMobileAndTablet && (
                    <Toggle
                        isOpen={isOpen}
                        variant={DropdownFilterVariant.Primary}
                        onClick={handleToggleList}
                        data-test-id="recent-dd"
                    >
                        <SortDashesIcon24 />
                        <StyledText>
                            <FormattedMessage id={sortingOptionText} />
                        </StyledText>
                        <ArrowIconWrapper isOpen={isOpen}>
                            <ChevronDownSquareCornersIcon24 />
                        </ArrowIconWrapper>
                    </Toggle>
                )}
            </Container>
            {isOpen && (
                <PopUp popUpWidth={"10.375rem"}>
                    <PopUpListItem
                        id="descending"
                        variant={DropdownFilterVariant.Primary}
                        isSelected={sortingOption === SortingFilterEnum.MostRecent}
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:most-recent" />
                    </PopUpListItem>
                    <PopUpListItem
                        id="ascending"
                        variant={DropdownFilterVariant.Primary}
                        isSelected={sortingOption === SortingFilterEnum.LeastRecent}
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:least-recent" />
                    </PopUpListItem>
                </PopUp>
            )}
        </>
    );
};

export default SortingFilters;
