import React, { createContext, useContext, useMemo, useState } from "react";
import type { ReactNode } from "react";
import { useQuery } from "react-query";

import { getCombinedCharts, getCustomCharts } from "@/api/sessions";
import { getCustomerSettings } from "@/api/settings";

import type { CircuitParameter } from "@/pages/session-detail/components/scoring/scoring.types";

import type { Client } from "@/enums/clients";

import useQueryParams from "@/utils/use-query-params";

//context type
interface SessionDetailsType {
    //CombinedChartsData
    isFetchingCombinedCharts: boolean;
    combinedChartsData: any;
    isErrorCombinedCharts: boolean;

    //CustomChartsData
    isFetchingCustomCharts: boolean;
    customChartsData: any;
    isErrorCustomCharts: boolean;

    //CustomerSettingsData
    isFetchingCustomerSettings: boolean;
    customerSettingsData: any;
    customerName: Client;
    isErrorCustomerSettings: boolean;

    //Scoring component | CircuitScoring
    isFilterOpen: boolean;
    setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filterOptions: CircuitParameter[] | null;
    setFilterOptions: React.Dispatch<React.SetStateAction<CircuitParameter[] | null>>;
    selectedIndex: number | null;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
    selectedFilterOption: CircuitParameter | null;
    setSelectedFilterOption: React.Dispatch<React.SetStateAction<CircuitParameter | null>>;
}

interface CombinedChartsDataProps {
    combinedChartsData: any;
    isFetching: boolean;
    customerName?: string;
}

interface CustomChartsDataProps {
    customChartsData: any;
    isFetching: boolean;
}

interface CustomerSettingsDataProps {
    customerName: string;
    emailProvider: string;
    scenarioImageUrl: string;
    simLogoUrl: string;
}

//create the context
const SessionDetailsContext = createContext<SessionDetailsType | undefined>(undefined);

//create the provider
export const SessionDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<CircuitParameter[] | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [selectedFilterOption, setSelectedFilterOption] = useState<CircuitParameter | null>(null);

    const params: any = useQueryParams();
    const userId = params.get("userId");
    const from: string = params.get("from");
    const to: string = params.get("to");
    const simulationId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    const isQueryEnabled = !!from || !!to || !!userId || !!simulationId || !!scenarioInstanceId;

    //CombinedChartsData
    //API request: /combined-custom-charts
    //EyeTracking, CockpitTimeline, TimelineChartDriving & TimelineChartFiring components
    //TimelineChartFlight uses a separate API call to CombinedCharts (through usePoll function)
    const {
        isFetching: isFetchingCombinedCharts,
        data: combinedChartsData,
        isError: isErrorCombinedCharts,
    } = useQuery<CombinedChartsDataProps>(
        ["getCombinedCharts", from, to, userId, simulationId, scenarioInstanceId],
        () =>
            getCombinedCharts({
                from,
                to,
                userId,
                simulationInstanceId: simulationId,
                scenarioInstanceId,
            }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: isQueryEnabled,
        },
    );

    //CustomChartsData
    //API request: /custom-charts
    //CognitiveLoad(SemiCircularGauge), FireMission & OverallScore components
    //LandingScore component uses a separate API call to CustomChartsData (through usePoll function)
    const {
        isFetching: isFetchingCustomCharts,
        data: customChartsData,
        isError: isErrorCustomCharts,
    } = useQuery<CustomChartsDataProps>(
        ["getCustomCharts", from, to, userId, simulationId, scenarioInstanceId],
        () =>
            getCustomCharts({
                from,
                to,
                userId,
                simulationInstanceId: simulationId,
                scenarioInstanceId,
            }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: isQueryEnabled,
        },
    );

    // CustomerSettingsData
    // API request: /settings/customer-settings
    const {
        isFetching: isFetchingCustomerSettings,
        data: customerSettingsData,
        isError: isErrorCustomerSettings,
    } = useQuery<CustomerSettingsDataProps>(["getCustomerSettings"], getCustomerSettings, {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: false,
        enabled: isQueryEnabled,
    });

    const customerName = useMemo(() => {
        return customerSettingsData?.customerName as Client;
    }, [isFetchingCustomerSettings, customerSettingsData]);

    return (
        <SessionDetailsContext.Provider
            value={{
                //CombinedChartsData
                isFetchingCombinedCharts,
                combinedChartsData,
                isErrorCombinedCharts,

                //CustomChartsData
                isFetchingCustomCharts,
                customChartsData,
                isErrorCustomCharts,

                // CustomerSettingsData
                isFetchingCustomerSettings,
                customerSettingsData,
                customerName,
                isErrorCustomerSettings,

                //Scoring component | CircuitScoring
                isFilterOpen,
                setIsFilterOpen,
                filterOptions,
                setFilterOptions,
                selectedIndex,
                setSelectedIndex,
                selectedFilterOption,
                setSelectedFilterOption,
            }}
        >
            {children}
        </SessionDetailsContext.Provider>
    );
};

//custom hook to export context's values & functions
export const useSessionDetailsContext = (): SessionDetailsType => {
    const context = useContext(SessionDetailsContext);

    if (!context) {
        throw new Error("useSessionDetails hook must be used within a ModalProvider");
    }

    return context;
};
