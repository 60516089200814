import { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import moment from "moment";

import {
    CalendarIcon24,
    CalendarIcon32,
    ChevronRightSquareCornersIcon24,
    ChevronLeftSquareCornersIcon24,
    ChevronDownSquareCornersIcon24,
} from "@/components/icons";

import { DropdownFilterVariant } from "@/types/filters";

import { DateFilterPresets, DateFilterPresetValues, FiltersList } from "@/enums/dashboard";

import useMediaQuery from "@/utils/hooks/use-media-query";

import {
    CalendarContainer,
    Columns,
    DateRangeLabel,
    PresetItem,
    Preset,
    PresetsContainer,
    PresetsList,
    SelectedPresetMessage,
} from "./date.styles";
import { ArrowIconWrapper, Container, IconAndTitleWrapper, PopUp, Toggle } from "./filters.styles";

type Props = {
    handleOnClick: (filterName: FiltersList) => void;
    onChange: (from: Date, to: Date) => void;
    initialDateRange: { from: Date | null; to: Date | null };
    isOpen: boolean;
    backgroundColor?: string;
    dateRange?: string | null;
    fullWidth?: boolean;
};

type FiltersState = {
    from: Date | null;
    to: Date | null;
    datePreset: string | null;
};

const applyDatePreset = (key: string): { from: Date; to: Date } => {
    const value: number = DateFilterPresetValues[key];
    const from: Date = new Date();
    const to: Date = new Date();

    switch (key) {
        case DateFilterPresets.Last7Days:
        case DateFilterPresets.Last14Days:
        case DateFilterPresets.Last30Days:
            from.setDate(from.getDate() - Math.abs(value));
            break;
        case DateFilterPresets.Yesterday:
            from.setDate(from.getDate() - Math.abs(value));
            to.setDate(to.getDate() - Math.abs(value));
            break;
    }

    return { from, to };
};

const DateFilter = ({
    onChange,
    handleOnClick,
    initialDateRange,
    isOpen,
    backgroundColor,
    dateRange,
    fullWidth,
    ...props
}: Props) => {
    const history = useHistory();
    const [filters, setFilters] = useState<FiltersState>({
        from: initialDateRange.from,
        to: initialDateRange.to,
        datePreset: DateFilterPresets.Last7Days,
    });
    const isMobileAndTablet = useMediaQuery("(max-width: 1023px)");

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (dateRange) {
            const { from, to } = applyDatePreset(dateRange);
            setFilters({ from: null, to: null, datePreset: dateRange });
            onChange(from, to);
        }

        if (Number(queryParams.get("from")) != 0) {
            const datefrom: Date = new Date(Number(queryParams.get("from")));
            const dateto: Date = new Date(Number(queryParams.get("to")));
            setFilters({ ...filters, from: datefrom, to: dateto });
        }
    }, [dateRange]);

    const onClose = useCallback(() => {
        if (!isOpen) return;
        handleOnClick(FiltersList.None);
    }, [isOpen]);

    const handleOnChange = (value: Date[]) => {
        const [from, to] = value;
        setFilters({ ...filters, from, to, datePreset: null });
        handleOnClick(FiltersList.None);

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("from", from.getTime().toString());
        queryParams.set("to", to.getTime().toString());
        queryParams.delete("dateRange");
        history.push({ search: queryParams.toString() });

        onChange(from, to);
    };

    const handleToggleList = useCallback(
        (event: any) => {
            event.stopPropagation();
            handleOnClick(isOpen ? FiltersList.None : FiltersList.DatePicker);
        },
        [isOpen, handleOnClick],
    );

    const handlePresetSelection = useCallback(
        (key: string) => {
            handleOnClick(FiltersList.None);
            const { from, to } = applyDatePreset(key);
            setFilters({ from, to, datePreset: key });
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("dateRange", key);
            queryParams.delete("from");
            queryParams.delete("to");
            history.push({ search: queryParams.toString() });

            onChange(from, to);
        },
        [onChange],
    );

    const maxDate = new Date();

    const { from, to, datePreset } = filters;
    const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });

    const selectedPreset = from && to ? null : datePreset || DateFilterPresets.Last7Days;
    const fromDate = from || new Date();
    const toDate = to || new Date();

    return (
        <Container ref={clickOutsideRef} backgroundColor={backgroundColor} {...props}>
            {isMobileAndTablet && (
                <Toggle
                    isOpen={isOpen}
                    variant={DropdownFilterVariant.Primary}
                    fullWidth={fullWidth}
                    backgroundColor={backgroundColor}
                    onClick={handleToggleList}
                    data-test-id="date-dd"
                >
                    <CalendarIcon32 />
                </Toggle>
            )}
            {!isMobileAndTablet && (
                <Toggle
                    isOpen={isOpen}
                    variant={DropdownFilterVariant.Primary}
                    fullWidth={fullWidth}
                    backgroundColor={backgroundColor}
                    onClick={handleToggleList}
                    data-test-id="date-dd"
                >
                    {selectedPreset ? (
                        <>
                            <IconAndTitleWrapper>
                                <CalendarIcon24 fill="#F2F2F2" />
                                <SelectedPresetMessage>
                                    <FormattedMessage id={`dashboard:${selectedPreset}`} />
                                </SelectedPresetMessage>
                            </IconAndTitleWrapper>
                            <ArrowIconWrapper isOpen={isOpen}>
                                <ChevronDownSquareCornersIcon24 />
                            </ArrowIconWrapper>
                        </>
                    ) : (
                        <>
                            <IconAndTitleWrapper>
                                <CalendarIcon24 fill="#F2F2F2" />
                                <DateRangeLabel>
                                    {moment(fromDate).format("DD-MM-yyy")} /{" "}
                                    {moment(toDate).format("DD-MM-yyy")}
                                </DateRangeLabel>
                            </IconAndTitleWrapper>
                            <ArrowIconWrapper isOpen={isOpen}>
                                <ChevronDownSquareCornersIcon24 />
                            </ArrowIconWrapper>
                        </>
                    )}
                </Toggle>
            )}
            {isOpen && (
                <PopUp popUpWidth={"27.75rem"}>
                    <Columns>
                        <PresetsContainer>
                            <PresetsList>
                                {Object.keys(DateFilterPresetValues).map((value: string) => (
                                    <Preset
                                        key={`preset-${value}`}
                                        onClick={() => handlePresetSelection(value)}
                                        selected={value === selectedPreset}
                                    >
                                        <PresetItem>
                                            <FormattedMessage id={`dashboard:${value}`} />
                                        </PresetItem>
                                    </Preset>
                                ))}
                            </PresetsList>
                        </PresetsContainer>
                        <CalendarContainer>
                            <Calendar
                                className="calendar-element"
                                selectRange={true}
                                onChange={handleOnChange}
                                value={[fromDate, toDate]}
                                minDate={new Date(2020, 0, 1)}
                                maxDate={maxDate}
                                showDoubleView={false}
                                minDetail={"month"}
                                formatShortWeekday={(locale, date) => moment(date).format("dd")}
                                nextLabel={<ChevronRightSquareCornersIcon24 />}
                                next2Label={null}
                                prevLabel={<ChevronLeftSquareCornersIcon24 />}
                                prev2Label={null}
                            />
                        </CalendarContainer>
                    </Columns>
                </PopUp>
            )}
        </Container>
    );
};

export default DateFilter;
